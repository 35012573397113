/* Slick Carousel Styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
    max-width: 100%;
    overflow-x: hidden;
    contain: content;
    content-visibility: auto;
}

/* Dark Theme */
@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #1a1a1a;
    }
}